<template>



  <div >

    <div id="overlay" ref="overlay" @click="off()"></div>
   
      <b-nav-item-dropdown
      v-if="!isLogeo"
      style=" z-index: 1000;"
      ref="dropdown"
      @hide="off"
                  right
                  toggle-class="d-flex align-items-center dropdown-user-link"
                  class="dropdown-user"
                >
                  <template #button-content>

                    <b-link @click="clickOpen"> 
                      <b-avatar
                      size="40"
                      variant="light-primary"
                    >
                
                    </b-avatar>

                      </b-link>

                
                  </template>

                  <b-row  class="row_login">


                      <b-col
                      sm="12"
                      md="12"
                      lg="12"
                      class="mx-auto"
                      align="center"
                      >

                      <img style="width: 150px;" src="/img/eqcoop.svg"/>
                                
                      <b-card-title
                      class=" bienvenido"
                      title-tag="h2"
                      style="margin-bottom:15px"
                      >
                      La primera cooperativa digital del Ecuador
                      </b-card-title>

                      </b-col>

                            <b-col
                                sm="12"
                                md="12"
                                lg="12"
                                align="center"
                              >

                          
                                <p style="color: #2f2e72 !important;font-weight: bold;font-size: 16px;text-align: center;margin-bottom: 10px;margin-top: 10px;">¿Cómo deseas ingresar?</p> 

                              

                              </b-col>

                              <formulario v-if="showComponent" :is-login="isLogin" :promo-code="promoCode"  :texto-button="textoButton"/>



                          </b-row>

                            
                                      
                                      </b-nav-item-dropdown>

      <b-nav-item-dropdown
      v-else
      style=" z-index: 1000;"
      @hide="off"
                  right
                  toggle-class="d-flex align-items-center dropdown-user-link"
                  class="dropdown-user"
                >
                  <template #button-content>

                    <b-link @click="clickOpen2"> 
                      <b-avatar
                      size="40"
                        :src="photoUser"
                        variant="light-primary"
                        style="cursor:pointer"
                    >

                    </b-avatar>
                
                    </b-link>

             
                
                  </template>

                  <b-row  class="row_login">


                     
                    <b-col
                    cols="3"
                          sm="3"
                          md="3"
                          lg="3"
                          align="center"
                          
                        >

                        <img v-if="isProfileVerificated" title="Usuario verificado" alt="Usuario verificado" class="badgeVerificacion4" src="/img/verificadoFinal.svg" />
                    <img v-else alt="Usuario no verificado" title="Usuario no verificado" class="badgeVerificacion4" src="/img/no_verificadoFinal.svg" />


                        <b-avatar
                        size="60"
                        :src="photoUser"
                        variant="light-primary"

                        
                    >


                    </b-avatar>

                    </b-col>

                    <b-col
                    cols="9"
                          sm="9"
                          md="9"
                          lg="9"
                       
                        >

                       <p style="text-align: left; font-weight: bold;margin-top:10px; margin-bottom: 2px; ">{{displayName}}</p>
                    <p style="text-align: left; font-size: 11px;">{{contacto}}</p>

                   

                  </b-col>

                  <b-col
                         cols="12"
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          align="center"
                        >

                        <hr>


                        </b-col>
                        <b-col
                         cols="6"
                          sm="6"
                          md="6"
                          lg="6"
                          xl="6"
                          align="center"
                        >

                        <b-button @click="off" style="border-radius:50px"  variant="outline-secondary" size="md" :to="{name: '/'}">

                        

                                           Dashboard

                      </b-button>
                  
                        
                        



                        </b-col>
                

                        <b-col
                         cols="6"
                          sm="6"
                          md="6"
                          lg="6"
                          xl="6"
                          align="center"
                        >
                  
                        <b-button style="border-radius:50px"  variant="primary" size="md" @click="logout">

                        

                                            Cerrar sesión

                          </b-button>




                        </b-col>

                          </b-row>

                            
                                      
               </b-nav-item-dropdown>

       

                </div>

</template>

<script>

    /* eslint-disable global-require */
import {  BRow, BCol, BAvatar, BButton, BLink, BCardTitle, BDropdown,BNavItemDropdown,BDropdownDivider, BDropdownItem, } from 'bootstrap-vue'

import Formulario from '../authentication/Formulario.vue'
import store from '@/store'
export default {
    components: {
    BRow,
    BCol,
    BCardTitle,
    Formulario,
    BAvatar,
    BButton,
    BLink, BDropdown,BNavItemDropdown,BDropdownDivider, BDropdownItem,
    },
    props: ['isLogin', 'isMobile', 'isLogeo'],
    data() {

      let foto= "";



      let isProfileVerificated=false;
      let userId= "";
      let displayName= "";
      let contacto="";
      let tokenAuth="";


      if(this.isLogeo){

        const user = localStorage.getItem('userData');
        const datos_json = JSON.parse(user);
        tokenAuth = datos_json.tokenAuth;
           
        foto= datos_json.photoUser;
        isProfileVerificated= datos_json.isProfileVerificated;
        userId=  datos_json.userId;
        displayName=  datos_json.displayName;
        contacto=  datos_json.phone;

        if(contacto === ""){
            contacto=  datos_json.email;

        }
      }



    
    return {

        photoUser:foto,
        isProfileVerificated:isProfileVerificated,
        showComponent:false,
        userId:userId,
        displayName:displayName,
        contacto:contacto,
        tokenAuth:tokenAuth,
        promoCode:"",
       textoButton:"Iniciar sesión con"

    }
    },
    created() {

    },
    watch: {

        

        },
    mounted() {

    
      this.$eventBus.$on('showModalLogin', () => {
        this.$refs.overlay.style.display="block";
        this.showComponent=true

        this.$refs.dropdown.show();
     
      })

    

    },
    methods: {

      off(){

      this.$refs.overlay.style.display="none";
      },

      openModalProfile(){
        this.$refs.modalProfile.show();
      },

      clickOpen2(){
      
        this.$refs.overlay.style.display="block";
      },

        clickOpen(){
          this.$refs.overlay.style.display="block";
        
          this.showComponent=true
     
           
        },



        logout() {
          this.off();
            this.exit(this.userId,this.tokenAuth, false);
  


            },


    },
}
</script>

<style lang="scss" >

.menu_login .dropdown-menu{
      width: 400px !important;
    }


</style>

    